<template>
   <Layout>
    <PageHeader slot="header" title="Settings"/>
    <SettingsTabs v-if="!mobile" :tab="tab" @update-tab="updateTab" :class="$route.path.endsWith('/home') ? 'tw-hidden' : ''"/>
    <router-view></router-view>
   </Layout>
</template>

<script>
import SettingsTabs from "@/components/Settings/SettingsTabs.vue";
import { isMobile } from "mobile-device-detect"

export default {
  name: "team-users",
  components: { 
    SettingsTabs
  },
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
      mobile() {
          return isMobile
      }
  },
  methods: {
    updateTab(val) {
      this.tab = val;
    },
  },
  created() {
  }
};
</script>

<style>
</style>